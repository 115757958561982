import "./Home.css";

// Dış Cephe
import out_1 from "../../images/out/1.jpg";
import out_2 from "../../images/out/2.jpg";
import out_3 from "../../images/out/3.jpg";
import out_4 from "../../images/out/4.jpg";
import out_5 from "../../images/out/5.jpg";
import out_6 from "../../images/out/6.jpg";
import out_7 from "../../images/out/7.jpg";
import out_8 from "../../images/out/8.jpg";
import out_9 from "../../images/out/9.jpg";
import out_10 from "../../images/out/10.jpg";
import out_11 from "../../images/out/11.jpg";
import out_12 from "../../images/out/12.jpg";
import out_13 from "../../images/out/13.jpg";
import out_14 from "../../images/out/14.jpg";
import out_15 from "../../images/out/15.jpg";
import out_v_1 from "../../images/out/out.mp4";
import pool_v_1 from "../../images/out/pool.mp4";
import pool_v_2 from "../../images/out/pool2.mp4";

// Zemin Kat
import f0_1 from "../../images/f0/1.jpg";
import f0_2 from "../../images/f0/2.jpg";
import f0_3 from "../../images/f0/3.jpg";
import f0_v_1 from "../../images/f0/zemin_v_1.mp4";

// 1. Kat
import f1_1 from "../../images/f1/1.jpg";
import f1_2 from "../../images/f1/2.jpg";
import f1_3 from "../../images/f1/3.jpg";
import f1_4 from "../../images/f1/4.jpg";
import f1_5 from "../../images/f1/5.jpg";
import f1_6 from "../../images/f1/6.jpg";
import f1_7 from "../../images/f1/7.jpg";
import f1_8 from "../../images/f1/8.jpg";
import f1_9 from "../../images/f1/9.jpg";
import f1_10 from "../../images/f1/10.jpg";
import f1_11 from "../../images/f1/11.jpg";
import f1_12 from "../../images/f1/12.jpg";
import f1_13 from "../../images/f1/13.jpg";
import f1_14 from "../../images/f1/14.jpg";
import f1_15 from "../../images/f1/15.jpg";

// 2. Kat
import f2_1 from "../../images/f2/1.jpg";
import f2_2 from "../../images/f2/2.jpg";
import f2_3 from "../../images/f2/3.jpg";
import f2_4 from "../../images/f2/4.jpg";
import f2_5 from "../../images/f2/5.jpg";
import f2_6 from "../../images/f2/6.jpg";
import f2_9 from "../../images/f2/9.jpg";
import f2_10 from "../../images/f2/10.jpg";
import f2_11 from "../../images/f2/11.jpg";
import f2_12 from "../../images/f2/12.jpg";
import f2_13 from "../../images/f2/13.jpg";
import f2_14 from "../../images/f2/14.jpg";
import f2_v_1 from "../../images/f2/VID_ODA1.mp4";
import f2_v_2 from "../../images/f2/VID_ODA2.mp4";
import f2_v_3 from "../../images/f2/VID_ODA3.mp4";
import f2_v_4 from "../../images/f2/kat2giris.mp4";

import CardComponent from "../cards/CardComponent";

// Gece
import gc1 from "../../images/gc/gc-1.jpg";
import gc2 from "../../images/gc/gc-2.jpg";
import gc3 from "../../images/gc/gc-3.jpg";
import gc4 from "../../images/gc/gc-4.jpg";
import gc5 from "../../images/gc/gc-5.jpg";
import gc6 from "../../images/gc/gc-6.jpg";
import gc7 from "../../images/gc/gc-7.jpg";
import gc8 from "../../images/gc/gc-8.jpg";
import gc9 from "../../images/gc/gc-9.jpg";
import gc10 from "../../images/gc/gc-10.jpg";
import gc11 from "../../images/gc/gc-11.jpg";
import gc12 from "../../images/gc/gc-12.jpg";
import gc13 from "../../images/gc/gc-13.jpg";
import gc14 from "../../images/gc/gc-14.jpg";
import gc15 from "../../images/gc/gc-15.jpg";
import gc16 from "../../images/gc/gc-16.jpg";

// Drone
import drone_video from "../../images/drone/drone.mp4";

export default function Home() {
  return (
    <>
      <div>
        <header className="header">
          <div className="header-overlay">
            <div className="container-sm">
              {" "}
              {/* Added container */}
              <h1 className="text-left">Manisa Gülbahçe Satılık Villa</h1>{" "}
              {/* Aligned text to the left */}
              <h2 className="text-left">20,000,000 ₺</h2>{" "}
              {/* Aligned text to the left */}
              <br />
              <p className="text-left">İletişim : +90 (530) 379 50 45</p>{" "}
              {/* Aligned text to the left */}
            </div>
          </div>
        </header>

        <section className="resimler-section">
          <div className="container mb-5 mt-5">
            <div className="row">
              <video src={drone_video} width="%100" height="%100" controls autoPlay loop muted>
              </video>

            </div>
          </div>
        </section>


        <section className="resimler-section">
          <div className="container mb-5 mt-5">
            <div className="row">
              <CardComponent
                title="Dış Cephe & Havuz"
                addLineBreak={false}
                imageSources = {[
                  { type: 'image', source: out_1 },
                  { type: 'image', source: out_2 },
                  { type: 'image', source: out_3 },
                  { type: 'image', source: out_4 },
                  { type: 'image', source: out_5 },
                  { type: 'image', source: out_6 },
                  { type: 'image', source: out_7 },
                  { type: 'image', source: out_8 },
                  { type: 'image', source: out_9 },
                  { type: 'image', source: out_10 },
                  { type: 'image', source: out_11 },
                  { type: 'image', source: out_12 },
                  { type: 'image', source: out_13 },
                  { type: 'video', source: out_v_1 },
                  { type: 'image', source: out_14 },
                  { type: 'image', source: out_15 },
                  { type: 'video', source: pool_v_1 },
                  { type: 'video', source: pool_v_2 },
                // Add more items as needed, images, videos, or other media types
                ]}
                imageText={["image 1", "image 2", "image 3"]}
              />

              <CardComponent
                  title= "Gece"
                  addLineBreak={false}
                  imageSources = {[
                    { type: 'image', source: gc1 },
                    { type: 'image', source: gc2 },
                    { type: 'image', source: gc3 },
                    { type: 'image', source: gc4 },
                    { type: 'image', source: gc5 },
                    { type: 'image', source: gc6 },
                    { type: 'image', source: gc7 },
                    { type: 'image', source: gc8 },
                    { type: 'image', source: gc9 },
                    { type: 'image', source: gc10 },
                    { type: 'image', source: gc11 },
                    { type: 'image', source: gc12 },
                    { type: 'image', source: gc13 },
                    { type: 'image', source: gc14 },
                    { type: 'image', source: gc15 },
                    { type: 'image', source: gc16 },
                  ]}
                  imageText={["image 1", "image 2", "image 3"]}
              />
            </div>
          </div>
        </section>

        <section className="resimler-section">
          <div className="container mb-5 mt-5">
            <div className="row">

              <CardComponent
                  title= "Zemin Kat"
                  addLineBreak={false}
                  imageSources = {[
                    { type: 'image', source: f0_1 },
                    { type: 'image', source: f0_2 },
                    { type: 'image', source: f0_3 },
                    { type: 'video', source: f0_v_1 }
                    // Add more items as needed, images, videos, or other media types
                  ]}

                  // imageSources={[f0_1, f0_2, f0_3]}
                  imageText={["image 1", "image 2", "image 3"]}
              />

              <CardComponent
                  title= "1. Kat"
                  addLineBreak={false}
                  imageSources = {[
                    { type: 'image', source: f1_1 },
                    { type: 'image', source: f1_2 },
                    { type: 'image', source: f1_3 },
                    { type: 'image', source: f1_4 },
                    { type: 'image', source: f1_5 },
                    { type: 'image', source: f1_6 },
                    { type: 'image', source: f1_7 },
                    { type: 'image', source: f1_8 },
                    { type: 'image', source: f1_9 },
                    { type: 'image', source: f1_10 },
                    { type: 'image', source: f1_11 },
                    { type: 'image', source: f1_12 },
                    { type: 'image', source: f1_13 },
                    { type: 'image', source: f1_14 },
                    { type: 'image', source: f1_15 }
                    // Add more items as needed, images, videos, or other media types
                  ]}
                  imageText={["image 1", "image 2", "image 3"]}
              />

              <CardComponent
                  title= "2. Kat"
                  // title= "2. Kat<br>&nbsp;"
                  addLineBreak={false}
                  imageSources = {[
                    { type: 'image', source: f2_1 },
                    { type: 'image', source: f2_2 },
                    { type: 'image', source: f2_3 },
                    { type: 'image', source: f2_4 },
                    { type: 'image', source: f2_5 },
                    { type: 'video', source: f2_v_1 },
                    { type: 'image', source: f2_6 },
                    { type: 'video', source: f2_v_2 },
                    { type: 'image', source: f2_9 },
                    { type: 'image', source: f2_10 },
                    { type: 'image', source: f2_11 },
                    { type: 'image', source: f2_12 },
                    { type: 'video', source: f2_v_3 },
                    { type: 'image', source: f2_13 },
                    { type: 'image', source: f2_14 },
                    { type: 'video', source: f2_v_4 }
                    // Add more items as needed, images, videos, or other media types
                  ]}
                  imageText={["image 1", "image 2", "image 3"]}
              />

            </div>
          </div>
        </section>

        <section className="emlak-detay-konum-section mb-5">
          <div class="row">
            <div class="col mb-3">
              <div class="card h-100 text-center">
                <div class="card-body">
                  <h2 className="text-center my-max-bold my-gray">
                    Emlak Detayları
                  </h2>
                  <table class="table table-hover">
                    <tbody>
                      <tr>
                        <td className="text-start">Adres</td>
                        <th scope="row">Gülbahçe / Yunusemre / Manisa</th>
                      </tr>
                      <tr>
                        <td className="text-start">İlan Durumu</td>
                        <th scope="row">Satılık</th>
                      </tr>
                      <tr>
                        <td className="text-start">Konut Şekli</td>
                        <th scope="row">Villa</th>
                      </tr>
                      <tr>
                        <td className="text-start">Oda + Salon Sayısı</td>
                        <th scope="row">5 + 2</th>
                      </tr>
                      <tr>
                        <td className="text-start">Brüt / Net M&#178;</td>
                        <th scope="row">452 m&#178; / 430 m&#178;</th>
                      </tr>
                      <tr>
                        <td className="text-start">Ada / Parsel</td>
                        <th scope="row">114 / 2</th>
                      </tr>
                      <tr>
                        <td className="text-start">Bina Yaşı</td>
                        <th scope="row">Sıfır Bina</th>
                      </tr>
                      <tr>
                        <td className="text-start">Isınma Tipi</td>
                        <th scope="row">Kombi</th>
                      </tr>
                      <tr>
                        <td className="text-start">Kat Sayısı</td>
                        <th scope="row">3 Katlı</th>
                      </tr>
                      <tr>
                        <td className="text-start">Banyo Sayısı</td>
                        <th scope="row">7</th>
                      </tr>
                      <tr>
                        <td className="text-start">Eşya Durumu</td>
                        <th scope="row">Eşyalı Değil</th>
                      </tr>
                      <tr>
                        <td className="text-start">Kullanım Durumu</td>
                        <th scope="row">Boş</th>
                      </tr>
                      <tr>
                        <td className="text-start">Krediye Uygunluk</td>
                        <th scope="row">Uygun</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col mb-3">
              <div class="card h-100">
                <div class="card-body">
                  <h2 className="text-center my-max-bold my-gray">Konum</h2>
                  <p>
                    Bu lüks villa, 705 m² genişliğindeki arsaya mükemmel bir
                    şekilde yerleştirilmiştir.{" "}
                  </p>

                  <br />

                  <div style={{ width: "100%" }}>
                    <iframe
                      width="100%"
                      height={450}
                      frameBorder={0}
                      scrolling="no"
                      marginHeight={0}
                      marginWidth={0}
                      src="https://maps.google.com/maps?width=100%25&height=450&hl=en&q=38.717593,%2027.343470+(G%C3%BClbah%C3%A7e%20Villa)&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                    >
                      &lt;a
                      href="https://www.maps.ie/distance-area-calculator.html"&gt;area
                      maps&lt;/a&gt;
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ozellikler-olanaklar-ek-bilgiler-section">
          <div class="row">
            <div class="col mb-3">
              <div class="card h-100">
                <div class="card-body">
                  <h2 className="text-center my-max-bold my-gray">
                    Özellikler & Olanaklar
                  </h2>
                  <p>
                    Hayalinizdeki yaşam tarzına adım atmak için <strong>Manisa'nın
                    Gülbahçe Mahallesi'nde, 705 m² arsa üzerinde</strong> yer alan bu
                    benzersiz villa sizleri bekliyor. Ultra lüks ve modern
                    tasarımıyla göz kamaştıran bu ev, sadece bir villa değil,
                    gerçek bir yaşam tarzının ifadesidir.
                  </p>
                  <p>
                    <strong>450 m²'lik kullanım alanıyla</strong>, zemin kat, 1. kat ve 2. kat
                    olmak üzere üç katlı bu villada, rahatınızı ve konforunuzu
                    en üst düzeye çıkarmak için düşünülmüş bir dizi özellik
                    bulunmaktadır.
                  </p>
                  <p>
                    Bu villa, kaliteli bir yaşam sunmak için her ayrıntının
                    düşünüldüğü özel bir tasarıma sahiptir. İşte bu evin size
                    sunabileceği bazı harika özellikler:
                  </p>
                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M576-189q-22 26-54.5 29.5T463-178L294-319q-26-21-45-48t-31-58q-11 12-26 18.5t-32 6.5H80v-320h80q29 0 51.5 18t27.5 47q38-58 98-91.5T466-780q45 0 85.5 16t74.5 45l170 141q26 21 28.5 53.5T806-466L576-189ZM260-518q0 45 19 84t53 68l185 154 255-307-184-154q-26.761-22.927-59.097-34.963Q496.566-720 462-720q-84 0-142.449 58.682Q261.103-602.637 260-518Zm256 52Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Lüks ve modern villa deneyiminize ekstra bir güvenlik
                        katmak için, bu özel villada <strong>&nbsp;<a className={'text-decoration-none'} target={'_blank'} href={'https://us.eufy.com/products/t88611d1?ref=navimenu_3_copy&fliter_category=security'}>Eufy Security (Anker)</a> &nbsp;</strong> tarafından
                        üretilen en son teknolojiye sahip güvenlik kameraları
                        kullanılmıştır.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M520-360h50v-90l84 90h66L608-480l112-120h-66l-84 90v-90h-50v240Zm-250 0h170v-50H320v-53h86q14.45 0 24.225-9.487Q440-481.975 440-496v-70q0-14.45-9.775-24.225Q420.45-600 406-600H270v50h120v54h-87q-14.025 0-23.513 9.487Q270-477.025 270-463v103Zm-90 240q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Güvenlik kameraları, <strong className={'text-primary'}>2K çözünürlükte kayıt</strong> yapabilme
                        özelliğiyle, evinizin etrafındaki her ayrıntıyı kristal
                        netlikte yakalayabilir.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="m747-357-42-42q17-17 26-38.267 9-21.266 9-44.733 0-23-8.5-45T706-566l41-42q25 25 39 57.378t14 67.5Q800-448 786-415t-39 58Zm84 84-41-42q33.385-33.234 51.692-76.117Q860-434 860-481.5q0-47.5-19.5-90.5T783-644l46-46q43.966 41.171 67.483 95.086Q920-541 920-481t-23 113.553Q874-313.895 831-273ZM260-40q-24.75 0-42.375-17.625T200-100v-760q0-24.75 17.625-42.375T260-920h440q24.75 0 42.375 17.625T760-860v146h-60v-56H260v580h440v-56h60v146q0 24.75-17.625 42.375T700-40H260Zm0-90v30h440v-30H260Zm0-700h440v-30H260v30Zm0 0v-30 30Zm0 700v30-30Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Ancak sadece yüksek çözünürlük değil, bu kameraların <strong className={'text-primary'}>Yapay
                        Zeka destekli</strong> özellikleri de dikkate değerdir. Akıllı
                        algoritmalar sayesinde, <strong className={'text-primary'}>kameralar hareketi algılar ve size
                        anında bildirim gönderir.</strong> Bu, evinizde olup bitenleri her
                        an kontrol edebileceğiniz anlamına gelir. İster işte,
                        ister seyahatte, telefonunuza gelen bildirimlerle evinizde
                        olup bitenler hakkında anında haberdar olabileceksiniz.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q8 0 17 .5t23 1.5q-36 32-56 79t-20 99q0 90 63 153t153 63q52 0 99-18.5t79-51.5q1 12 1.5 19.5t.5 14.5q0 150-105 255T480-120Zm0-60q109 0 190-67.5T771-406q-25 11-53.667 16.5Q688.667-384 660-384q-114.689 0-195.345-80.655Q384-545.311 384-660q0-24 5-51.5t18-62.5q-98 27-162.5 109.5T180-480q0 125 87.5 212.5T480-180Zm-4-297Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Eufy Security kameraları aynı zamanda <strong className={'text-primary'}>gece görüşü
                        özelliği</strong>ne de sahiptir. Bu sayede, karanlıkta bile
                        mükemmel bir görüntü kalitesi elde edebilirsiniz. Ev ve
                        çevresinin 24 saat boyunca güvende olduğundan emin
                        olabilirsiniz.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="m80-480 400-360 200 180v-120h80v192l120 108h-89L480-759 169-480H80Zm290 320v-220h220v220H370Zm60-60h100v-100H430v100Zm50-50Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        <strong className={'text-primary'}>Gizli ve izolasyonlu kremik çatısı</strong>, sizi dış etkenlerden
                        korurken iç mekanı konforlu ve sessiz kılar.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="m80-80 80-400h640l80 400H80Zm40-740v-60h120v60H120Zm34 680h296v-110H176l-22 110Zm80-460-43-42 85-85 43 42-85 85Zm-46 290h262v-110H210l-22 110Zm292-390q-74 0-125-52.5T300-880h60q3 50 37 85t83 35q49 0 83-35t37-85h60q-4 75-55 127.5T480-700Zm0-180Zm-30 360v-120h60v120h-60Zm60 380h296l-22-110H510v110Zm0-170h262l-22-110H510v110Zm216-291-85-85 42-42 86 84-43 43Zm-6-219v-60h120v60H720Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        <strong className={'text-primary'}>Güneş enerjisi sistemi</strong>, çevreye duyarlı bir yaşam için
                        tasarlanmıştır ve enerji maliyetlerinizi azaltır.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M272.765-226Q226-226 193-258.958 160-291.917 160-339v-357h60v357q0 22 15.5 37.5t37.57 15.5q22.07 0 37-15.5T325-339v-244q0-47.083 32.941-80.042 32.941-32.958 80-32.958t79.559 32.958Q550-630.083 550-583v244q0 22 15.5 37.5t37.57 15.5q22.07 0 37-15.5T655-339v-287q0-45.833 32.083-77.917Q719.167-736 765-736h80l-57-57 42-43 130 130-130 129-42-43 58-56h-81q-21.25 0-35.625 14.375T715-626v287q0 47.083-32.735 80.042Q649.529-226 602.765-226 556-226 523-258.958 490-291.917 490-339v-244q0-22-14.93-37.5t-37-15.5q-22.07 0-37.57 15.5T385-583v244q0 47.083-32.735 80.042Q319.529-226 272.765-226ZM100-96q-24.75 0-42.375-17.625T40-156v-335h880v335q0 24.75-17.625 42.375T860-96H100Zm0-60h760v-275H100v275Zm760-275H100h760Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Tüm odalarda <strong className={'text-primary'}>kalorifer tesisatı sistemi</strong>, kış aylarında
                        sizi sıcak tutar ve evinizi her odada eşit bir şekilde
                        ısıtır.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M298-263q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9Zm182 0q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9Zm182 0q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9ZM200-413v-60q0-107 72.5-187T450-751v-89h60v89q105 11 177.5 91T760-473v60H200Zm60-60h440q0-91-64.288-155.5t-155.5-64.5Q389-693 324.5-628.65 260-564.3 260-473Zm38 353q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9Zm182 0q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9Zm182 0q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9ZM480-473Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Villada toplamda <strong className={'text-primary'}>7 adet birinci sınıf banyo</strong> bulunmaktadır.
                        Özel seramikler, şık duşakabinler ve Artema ve Vitra
                        ürünleriyle donatılmıştır.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M80-200v-255q0-25 10-47t30-36v-116q0-45 30.5-75.5T226-760h180q22 0 41 10t33 27q14-17 32.5-27t40.5-10h180q45 0 76 30.5t31 75.5v116q20 14 30 36t10 47v255h-60v-80H140v80H80Zm430-355h270v-99q0-20-13.5-33T733-700H550q-17 0-28.5 14T510-654v99Zm-330 0h270v-99q0-18-11.5-32T410-700H226q-19 0-32.5 13.5T180-654v99Zm-40 215h680v-115q0-17-11.5-28.5T780-495H180q-17 0-28.5 11.5T140-455v115Zm680 0H140h680Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Üst katta bulunan 3 yatak odası, size <strong className={'text-primary'}>gömme elbise
                        dolapları, özel banyoları </strong>ve<strong className={'text-primary'}> balkonlarıyla </strong>rahat ve ferah
                        bir yaşam sunar.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M160-160v-350H80v-60h155q-28.875 0-49.438-20.562Q165-611.125 165-640v-160h230v160q0 29-20.562 49.5Q353.875-570 325-570h325v-120q0-21.25-14.375-35.625T600-740q-18 0-32 11.5T551-700h-61q5-42 36-71t74-29q45.833 0 77.917 32.083Q710-735.833 710-690v120h170v60h-80v350H160Zm65-470h110v-110H225v110Zm-5 410h230v-290H220v290Zm290 0h230v-290H510v290ZM225-630h110-110Zm255 265Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Orta katta, geniş bir salon, <strong className={'text-primary'}>modern bir mutfak, banyo </strong>ve
                        <strong className={'text-primary'}> teras </strong>bulunmaktadır. Bu alan, aile ve arkadaşlarınızla
                        keyifli zaman geçirebileceğiniz bir buluşma noktasıdır.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M40-160v-160q0-29 20.5-49.5T110-390h141q17 0 32.5 8.5T310-358q29 42 74 65t96 23q51 0 96-23t75-65q11-15 26-23.5t32-8.5h141q29 0 49.5 20.5T920-320v160H660v-119q-36 33-82.5 51T480-210q-51 0-97-18t-83-51v119H40Zm440-170q-35 0-67.5-16.5T360-392q-16-23-38.5-37T273-448q29-30 91-46t116-16q54 0 116.5 16t91.5 46q-26 5-48.5 19T601-392q-20 29-52.5 45.5T480-330ZM160-460q-45 0-77.5-32.5T50-570q0-46 32.5-78t77.5-32q46 0 78 32t32 78q0 45-32 77.5T160-460Zm640 0q-45 0-77.5-32.5T690-570q0-46 32.5-78t77.5-32q46 0 78 32t32 78q0 45-32 77.5T800-460ZM480-580q-45 0-77.5-32.5T370-690q0-46 32.5-78t77.5-32q46 0 78 32t32 78q0 45-32 77.5T480-580Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Zemin katta yer alan havuza sıfır olan kat, <strong className={'text-primary'}>50 m²'lik bir
                        salona</strong>, <strong className={'text-primary'}>bir yatak odasına</strong> ve <strong className={'text-primary'}>iki banyoya</strong> sahiptir. Bu
                        alan, konuklarınızı ağırlamak ve özel etkinlikler
                        düzenlemek için idealdir.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M160-120v-720h640v720H160Zm60-390h220v-40h80v40h220v-270H220v270Zm0 330h520v-270H220v270Zm0 0h520-520Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Dış doğramalar, Ege Pen'in son serisi olan <strong className={'text-primary'}>özel renkli</strong> ve <strong className={'text-primary'}>ısıcam konfor serisi argon gazlı pencereler</strong>le
                        tamamlanmıştır. Bu sayede, enerji tasarrufu sağlanırken,
                        iç mekanlarınız da sessiz ve huzurlu olur.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M298-278q-77-20-127.5-84T120-510q0-79 37.5-140.5T240-754q45-42 82.5-64l37.5-22v99q0 25 18 42.5t43 17.5q14 0 26-6t20-17l13-16q43 24 71.5 62.5T592-572l-52 52q-2-34-16.5-64.5T486-641q-15 8-31.5 14t-33.5 6q-45 0-76-31.5T303-729q-49 45-86 99t-37 120q0 41 16.5 75.5T241-375q-1-4-1-7v-7q0-23 9.5-44.5T276-472l84-84 86 84q6 6 10.5 12.5T465-445l-46 46q-2-8-6-16t-10-14l-43-43-42 43q-8 8-13 18.5t-5 21.5q0 23 14.5 39t36.5 19l-53 53Zm62-278Zm0 0ZM258-70l-42-42 592-592 42 42-184 184h184v60H606l-30 30 70 70h204v60H706l115 115-42 42-115-115v146h-60v-206l-70-70-30 30v246h-60v-186L258-70Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Bina, <strong className={'text-primary'}>dış cephesi 24 gözenekli yalıtımlı bims tuğlalarla </strong>
                        inşa edilmiştir. Bu sayede, iç mekanlarınız ideal
                        sıcaklıkta tutulur ve enerji tasarrufu sağlanır.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M440-450q13 0 21.5-9t8.5-21q0-13-8.5-21.5T440-510q-12 0-21 8.5t-9 21.5q0 12 9 21t21 9ZM260-120v-60l280-49v-466q0-14-7.5-24.5T512-732l-252-39v-59l266 46q32 5 53 30.5t21 57.5v516l-340 60Zm-140 0v-60h80v-590q0-26 17.5-43t42.5-17h440q26 0 43 17t17 43v590h80v60H120Zm140-60h440v-590l-440-1v591Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        İç kapılar <strong className={'text-primary'}>özel renkli lake malzemeden</strong> yapılmıştır. Zarif
                        tasarımlarıyla evinize modern bir hava katarlar.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M40-120v-470h60v80h110v-330h60v80h100v-80h60v80h100v-80h60v80h100v-80h60v330h110v-80h60v470H570v-120q0-38-26-64t-64-26q-38 0-64 26t-26 64v120H40Zm60-60h230v-60q0-63 43.5-106.5T480-390q63 0 106.5 43.5T630-240v60h230v-270H690v-250H270v250H100v270Zm270-310h60v-110h-60v110Zm160 0h60v-110h-60v110Zm-50 50Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Arsanın etrafı <strong className={'text-primary'}>C25 bahçe duvarlarıyla</strong> çevrilidir, böylece
                        size özel bir yaşam alanı sunar.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M120-120v-60h80v-600q0-24 18-42t42-18h440q24 0 42 18t18 42v600h80v60H120Zm580-60v-600H260v600h440ZM567-449q14.45 0 24.225-9.775Q601-468.55 601-483q0-14.45-9.775-24.225Q581.45-517 567-517q-14.45 0-24.225 9.775Q533-497.45 533-483q0 14.45 9.775 24.225Q552.55-449 567-449ZM260-780v600-600Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Bina giriş kapısı, <strong className={'text-primary'}>hava şartlarına dayanıklı çelik kapı</strong>dır
                        ve <strong className={'text-primary'}>bahçe kapısı da özel üretim bir model</strong>dir.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M216-376v-208h528v208H216Zm236-394v-110h60v110h-60Zm297 107-42-42 68-68 42 42-68 68ZM452-80v-110h60v110h-60Zm323-110-68-68 42-42 68 68-42 42ZM211-663l-68-68 42-42 68 68-42 42Zm-26 473-42-42 68-68 42 42-68 68Zm91-246h408v-88H276v88Zm0 0v-88 88Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Bina dış aydınlatmaları ve bahçe aydınlatmaları, <strong className={'text-primary'}>harekete
                        duyarlı sensörler</strong>le donatılmıştır. Bu sayede, enerji
                        tasarrufu sağlanırken, güvenli bir çevre oluşturulur.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M340.118-290Q361-290 375.5-304.618q14.5-14.617 14.5-35.5Q390-361 375.382-375.5q-14.617-14.5-35.5-14.5Q319-390 304.5-375.382q-14.5 14.617-14.5 35.5Q290-319 304.618-304.5q14.617 14.5 35.5 14.5Zm0-280Q361-570 375.5-584.618q14.5-14.617 14.5-35.5Q390-641 375.382-655.5q-14.617-14.5-35.5-14.5Q319-670 304.5-655.382q-14.5 14.617-14.5 35.5Q290-599 304.618-584.5q14.617 14.5 35.5 14.5Zm280 280Q641-290 655.5-304.618q14.5-14.617 14.5-35.5Q670-361 655.382-375.5q-14.617-14.5-35.5-14.5Q599-390 584.5-375.382q-14.5 14.617-14.5 35.5Q570-319 584.618-304.5q14.617 14.5 35.5 14.5Zm0-280Q641-570 655.5-584.618q14.5-14.617 14.5-35.5Q670-641 655.382-655.5q-14.617-14.5-35.5-14.5Q599-670 584.5-655.382q-14.5 14.617-14.5 35.5Q570-599 584.618-584.5q14.617 14.5 35.5 14.5ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Yatak odaları haricindeki tüm zeminler, <strong className={'text-primary'}>özel granit
                        taşları</strong>yla döşenmiştir. Bu yüksek kaliteli malzeme,
                        evinizin zarafetini ve şıklığını vurgular.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-sm-1 text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="48"
                        viewBox="0 -960 960 960"
                        width="48"
                      >
                        <path d="M743-631q-26 26-58 38.5T619-580q-34 0-66-12.5T495-631l-75-75q-17-17-37.5-25t-42.5-8q-22 0-42.5 8T260-706l-68 68-43-43 68-68q26-26 58-38.5t65-12.5q33 0 64.5 12.5T462-749l75 75q17 17 37.5 25.5T618-640q23 0 44-8.5t38-25.5l68-68 43 43-68 68Zm0 210q-26 26-58 38.5T619-370q-34 0-66-12.5T495-421l-75-75q-17-17-37.5-25t-42.5-8q-22 0-42.5 8T260-496l-68 68-43-42 68-69q26-26 58-38.5t65-12.5q33 0 64.5 12.5T462-539l75 75q17 17 38 25.5t44 8.5q23 0 43.5-8.5T700-464l68-68 43 43-68 68Zm-1 210q-26 26-57.5 38.5T619-160q-34 0-66-12.5T495-211l-76-75q-17-17-37.5-25t-42.5-8q-22 0-42.5 8T259-286l-68 68-42-42 68-69q26-26 57.5-38.5T339-380q33 0 65 12.5t58 38.5l75 75q17 17 38 25.5t44 8.5q23 0 43.5-8.5T700-254l68-68 42 43-68 68Z" />
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Tüm odalarda <strong className={'text-primary'}>uydu ve klima tesisatı</strong> yapılmıştır.
                        İstediğiniz zaman iletişimde kalabilir ve iç
                        mekanlarınızın sıcaklığını kontrol edebilirsiniz.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-sm-1 text-center">
                      <svg xmlns="http://www.w3.org/2000/svg"
                           height="48"
                           viewBox="0 -960 960 960"
                           width="48"
                      >
                        <path d="M273-160 80-353l193-193 42 42-121 121h316v60H194l121 121-42 42Zm414-254-42-42 121-121H450v-60h316L645-758l42-42 193 193-193 193Z"/>
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Makul bir teklifle <strong className={'text-primary'}>takas imkanı</strong> da düşünülebilir.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-sm-1 text-center">
                      <svg xmlns="http://www.w3.org/2000/svg"
                           height="48"
                           viewBox="0 -960 960 960"
                           width="48">
                        <path d="M80-160v-60h240q-20-91-87.5-159.5T80-470q20-5 39.5-7.5T160-480q134 0 227 93t93 227H80Zm460 0q0-42-8-73.5T512-296q40-88 120-136t168-48q21 0 40.5 2.5T880-470q-85 22-154 91.5T640-220h240v60H540Zm-58-193q-9-76 13-142t64.5-115Q602-659 662-688t127-31q-56 35-105.5 95.5T621-494q-44 21-80 58.5T482-353Zm-61-76q-16-15-35-30t-32-23q3-12 4.5-26.5T360-532q0-76-24-144t-68-124q70 28 124 88.5T468-573q-18 32-30.5 69.5T421-429Z"/>
                      </svg>
                    </div>

                    <div className="col-sm-11 d-flex align-items-center">
                      <div>
                        Bahçe çimlendirilmesi henüz tamamlanmamış olup, alıcı tarafından talep edildiği takdirde teslimat öncesi tamamlanacaktır.
                      </div>
                    </div>
                  </div>

                  <p>
                    Bu villa, uzman mimarlar tarafından tasarlanmış ve
                    projelendirilmiştir. Oturma ruhsatına sahiptir ve kusursuz
                    bir şekilde inşa edilmiştir.
                  </p>
                  <p>
                    Bu benzersiz villa, konumundan özelliklerine kadar her
                    açıdan mükemmel bir yaşam sunuyor. Siz de bu lüks ve modern
                    evde hayallerinizi gerçeğe dönüştürmek için şimdi harekete
                    geçin.
                  </p>
                  <p>
                    Detaylar için bize ulaşın ve bu eşsiz yaşam
                    deneyimini keşfetmek için bir adım atın!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="ozellikler-olanaklar-ek-bilgiler-section">
          <div class="card shadow p-3 mb-5 mt-5 bg-body border-radius-30px border-0">
            <h2 className="text-center my-max-bold my-gray mb-3">
              Özellikler & Olanaklar
            </h2>

            
          </div>
        </section> */}
      </div>
    </>
  );
}
