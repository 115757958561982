import React, { useState } from 'react';
import { Card, Button, Modal, Carousel } from 'react-bootstrap';
import './CardComponent.css'

const CardComponent = ({ title, imageSources, addLineBreak }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div className="col-sm mb-3">
      
      <div class="card card-hoverable prop-det text-center">
        <img class="card-img" src={imageSources[0].source}/>
        <div>
          {addLineBreak ? (
              <h5 className="mt-3 card-title" dangerouslySetInnerHTML={{ __html: title }}></h5>
          ) : (
              <h5 className="mt-3 card-title">{title}</h5>
          )}
          {/* Render the images and other content */}
        </div>
          <Button variant="primary" onClick={openModal}>
            Resimleri Gör
          </Button>
          
          </div>

      <Modal show={modalIsOpen} onHide={closeModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
          
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*<Carousel activeIndex={activeIndex} onSelect={handleSelect}>*/}
          {/*  {imageSources.map((imageSrc, index) => (*/}
          {/*    <Carousel.Item key={index}>*/}
          {/*      <img className="d-block w-100" src={imageSrc} alt="Carousel Image" />*/}
          {/*    </Carousel.Item>*/}
          {/*  ))}*/}
          {/*</Carousel>*/}


          <Carousel activeIndex={activeIndex} onSelect={handleSelect} interval={null}>
            {imageSources.map((item, index) => (
                <Carousel.Item key={index}>
                  {item.type === 'image' ? (
                      <img className="d-block w-100" src={item.source} alt="Carousel Image" />
                  ) : item.type === 'video' ? (
                      <video className="d-block w-100" controls>
                        <source src={item.source} type="video/mp4" />
                        {/* You can add more <source> tags for different video formats (e.g., webm) if needed */}
                        Your browser does not support the video tag.
                      </video>
                  ) : (
                      <p>Invalid media type.</p>
                  )}
                </Carousel.Item>
            ))}
          </Carousel>



        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CardComponent;
